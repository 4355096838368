<template>
  <div class="popup_wrap container_upload_pop" style="width:1200px; height: 750px;">
    <button class="layer_close" @click="$emit('close')">close</button>
    <div class="popup_cont">
      <h1 class="page_title">Container Information Excel Upload</h1>
      <div class="content_box">
        <div class="text_right">
          <span class="title">{{ $t('msg.ONEX050G020.002') }}</span> <!-- B/L No. -->
          <span class="txt_bar2"></span>
          <span>{{ parentInfo.blNo }}</span>
        </div>
        <table class="tbl_col" v-if="usrCtrCd !== 'JP' && usrCtrCd !== 'PH' && usrCtrCd !== 'VN'">
          <colgroup>
            <col style="width: 15%">
            <col style="width: 8%">
            <col style="width: 8%">
            <col style="width: 10%">
            <col style="width: 8%">
            <col style="width: 10%">
            <col style="width: 10%">
            <col style="width: 21%">
            <col style="width: 5%">
          </colgroup>
          <thead>
            <tr>
              <th>{{ $t('msg.CSBL200.197') }}</th>
              <th>{{ $t('msg.CSBK100.272') }}</th>
              <th>{{ $t('msg.CSBK100.273') }}</th>
              <th>{{ $t('msg.CSBL200.252') }}</th>
              <th>Empty</th>
              <th>{{ $t('msg.ONEX010P090.060') }}</th>
              <th>{{ $t('msg.ONEX010P090.061') }}</th>
              <th>{{ $t('msg.CSBL200.251') }}</th>
              <th></th>
            </tr>
          </thead>
          <tbody v-if="containerList.length > 0">
            <tr v-for="(vo, idx) in containerList" :key="idx">
              <td :class="isRed(vo, 'cntrNo')">{{ vo.cntrNo }}</td>
              <td :class="isRed(vo, 'cntrSz')">{{ vo.cntrSzCd }}</td>
              <td>{{ vo.cntrTypCd }}</td>
              <td :class="isRed(vo, 'shprVanYn')">{{ vo.shprVanYn }}</td>
              <td :class="isRed(vo, 'feUnitCd')">{{ vo.feUnitCd }}</td>
              <td>{{ vo.wt }}</td>
              <td>{{ vo.pkgQty }}</td>
              <td class="text_left">
                <p v-for="(svo, i) in vo.sealList" :key="i">
                  Seal No. {{ i + 1 }} : {{ svo.sealNo }}
                </p>
              </td>
              <td>
                <button
                  class="tbl_icon minus"
                  @click="removeBLContainer(idx)"
                >
                  minus
                </button>
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <td colspan="8">{{ $t('msg.CCIT100.034') }}</td>
            </tr>
          </tbody>
        </table>
        <table class="tbl_col" v-if="usrCtrCd === 'PH' || usrCtrCd === 'VN'">
          <colgroup>
            <col style="width: 15%">
            <col style="width: 7%">
            <col style="width: 8%">
            <col style="width: 10%">
            <col style="width: 8%">
            <col style="width: 8%">
            <col style="width: 8%">
            <col style="width: 10%">
            <col style="width: 21%">
            <col style="width: 5%">
          </colgroup>
          <thead>
            <tr>
              <th>{{ $t('msg.CSBL200.197') }}</th>
              <th>{{ $t('msg.CSBK100.272') }}</th>
              <th>{{ $t('msg.CSBK100.273') }}</th>
              <th>{{ $t('msg.CSBL200.252') }}</th>
              <th>Empty</th>
              <th>{{ $t('msg.ONEX010P090.060') }}</th>
              <th>{{ $t('msg.ONEX010P090.061') }}</th>
              <th>CBM</th>
              <th>{{ $t('msg.CSBL200.251') }}</th>
              <th></th>
            </tr>
          </thead>
          <tbody v-if="containerList.length > 0">
            <tr v-for="(vo, idx) in containerList" :key="idx">
              <td :class="isRed(vo, 'cntrNo')">{{ vo.cntrNo }}</td>
              <td :class="isRed(vo, 'cntrSz')">{{ vo.cntrSzCd }}</td>
              <td>{{ vo.cntrTypCd }}</td>
              <td :class="isRed(vo, 'shprVanYn')">{{ vo.shprVanYn }}</td>
              <td :class="isRed(vo, 'feUnitCd')">{{ vo.feUnitCd }}</td>
              <td>{{ vo.wt }}</td>
              <td>{{ vo.pkgQty }}</td>
              <td>{{ vo.meaCbm }}</td>
              <td class="text_left">
                <p v-for="(svo, i) in vo.sealList" :key="i">
                  Seal No. {{ i + 1 }} : {{ svo.sealNo }}
                </p>
              </td>
              <td>
                <button
                  class="tbl_icon minus"
                  @click="removeBLContainer(idx)"
                >
                  minus
                </button>
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <td colspan="9">{{ $t('msg.CCIT100.034') }}</td>
            </tr>
          </tbody>
        </table>
        <table v-if="usrCtrCd === 'JP'" class="tbl_col">
          <colgroup>
            <col width="15%">
            <col width="7%">
            <col width="8%">
            <col width="10%">
            <col width="8%">
            <col width="8%">
            <col width="8%">
            <col width="10%">
            <col width="21%">
            <col width="5%">
          </colgroup>
          <thead>
            <tr>
              <th>{{ $t('msg.CSBL200.197') }}</th>
              <th>{{ $t('msg.CSBK100.272') }}</th>
              <th>{{ $t('msg.CSBK100.273') }}</th>
              <th>{{ $t('msg.CSBL200.252') }}</th>
              <th>Cargo Wgt</th>
              <th>Tare Wgt</th>
              <th>Pkg</th>
              <th>Measure</th>
              <th>{{ $t('msg.CSBL200.251') }}</th>
              <th></th>
            </tr>
          </thead>
          <tbody v-if="containerList.length > 0">
            <tr v-for="(vo, idx) in containerList" :key="idx">
              <td :class="isRed(vo, 'cntrNo')">{{ vo.cntrNo }}</td>
              <td :class="isRed(vo, 'cntrSz')">{{ vo.cntrSzCd }}</td>
              <td>{{ vo.cntrTypCd }}</td>
              <td :class="isRed(vo, 'shprVanYn')">{{ vo.shprVanYn }}</td>
              <td>{{ vo.wt }}</td>
              <td>{{ vo.tareWt }}</td>
              <td>{{ vo.pkgQty }}</td>
              <td>{{ vo.meaCbm }}</td>
              <td class="text_left">
                <p v-for="(svo, i) in vo.sealList" :key="i">
                  Seal No. {{ i + 1 }} : {{ svo.sealNo }}
                </p>
              </td>
              <td>
                <button
                  class="tbl_icon minus"
                  @click="removeBLContainer(idx)"
                >
                  minus
                </button>
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <td colspan="9">{{ $t('msg.CCIT100.034') }}</td>
            </tr>
          </tbody>
        </table>

        <table class="tbl_row mt10">
          <colgroup>
            <col width="20%">
            <col width="80%">
          </colgroup>
          <tbody>
            <tr>
              <th>{{ $t('msg.ONEX050G020.019') }}</th>
              <td class="text_left">
                <span>
                  <input @change="fnFileUpload()" type="file" id="inpFile" />
                </span>
                <span>
                  <a class="button sm" @click.prevent="excelUpload">{{ $t('msg.ONEX050G020.020') }}</a>
                  <a class="button sm ml5" @click.prevent="excelDownload">{{ $t('msg.ONEX050G020.021') }}</a>
                </span>
              </td>
            </tr>
          </tbody>
        </table>

        <div class="mt10 text_center">
          <a class="button blue lg" @click.prevent="containerUpload()">{{ $t('msg.CSBL200.181') }}</a>
          <a class="button gray lg ml5" @click.prevent="$emit('close')">{{ $t('msg.ONEX050G020.028') }}</a>
        </div>
      </div>
    </div>
    <win-layer-pop class="winLayerPop">
      <component
        v-if="customComponent"
        :is="customComponent"
        @close="layerClose"
        :parent-info="popupParams"
        @set-dext-file-info="arrangeUploadParam"
      />
    </win-layer-pop>
  </div>
</template>

<script>
import { rootComputed } from '@/store/helpers'
import trans from '@/api/rest/trans/trans'
import bookings from '@/api/rest/trans/bookings'
import commons from '@/api/services/commons'

export default ({
  name: 'ContainerExcelUploadPop',
  components: {
    'win-layer-pop': () => import('@/pages/trans/popup/WinLayerPop'),
    'vgm-upload-pop': () => import('@/pages/trans/popup/VgmUploadPop')
  },
  props: {
    parentInfo: {
      type: Object,
      default: () => {
        return {
          blNo: '',
          bkgNo: '',
          checkCntr: '',
          polPortCd: '',
          type: ''
        }
      }
    }
  },
  data () {
    return {
      usrCtrCd: '',
      uploadFileInfos: [],
      containerList: [],
      customComponent: null,
      popupParams: [],
      uploadParams: [],
      tempFile: {
        id: '',
        msg: ''
      },
      firstUploadFlag: false
    }
  },
  computed: {
    ...rootComputed
  },
  created () {
    if (this.memberDetail !== undefined) {
      this.usrCtrCd = this.memberDetail.userCtrCd
    }
  },
  methods: {
    arrangeUploadParam () {
      this.firstUploadFlag = true
      this.formData.uploadFileInfos = []
      this.popupParams.map((item) => (item.newFlag = item.new))
      this.formData.uploadFileInfos = this.popupParams
    },
    layerClose (flag) {
      if (flag === 'pop2') {
        //a b: a태그 하위의 모든 b
        this.customComponent = null
        this.$ekmtcCommon.layerClose('.container_upload_pop .popup_dim')
      } else {
        this.customComponent = null
        this.$ekmtcCommon.layerClose('.popup_dim')
      }
    },
    async excelUpload () {
      let fileName = document.getElementById('inpFile').value

      if (!fileName) {
        this.$ekmtcCommon.alertDefault(this.$t('art.CMATK169')) //등록할 파일을 선택하세요.
        return
      }

      if (!new RegExp('xls$', 'i').test(fileName) && !new RegExp('xlsx$', 'i').test(fileName)) {
        this.$ekmtcCommon.alertDefault(this.$t('msg.ONEX050G020.039')) //Please choose one of the excel files. [.xls]
      } else {
        const params = {
          bkgNo: this.parentInfo.bkgNo,
          checkCntr: this.parentInfo.checkCntr,
          fileId: this.tempFile.id
        }
        const result = await trans.containerExcelUpload(params)
        if (result.data.xlsErrorYn === 'Y') {
          this.$ekmtcCommon.alertDefault(this.$t('msg.ONEX050G020.044')) // 잘못된 양식입니다.
          this.$set(this, 'containerList', [])
        } else {
          const containerList = result.data.containers
          let sealList = []
          containerList.forEach(c => {
            if (!['Y', 'N'].includes(c.shprVanYn)) {
              c.shprVanYnErrorYn = 'Y'
            }
            if (!['F', 'E'].includes(c.feUnitCd)) {
              c.feUnitCdErrorYn = 'Y'
            }

            sealList = []
            for (let i = 1; i <= 5; i++) {
              if (this.$ekmtcCommon.isNotEmpty(c['sealNo' + i])) {
                sealList.push({ sealNo: c['sealNo' + i] })
              }
            }

            this.$set(c, 'sealList', sealList)
          })

          this.$set(this, 'containerList', containerList)
        }
      }
    },
    async excelDownload () {
      let filenm = ''

      if (this.usrCtrCd === 'PH') {
        filenm = 'ExcelTemplatePH.xls'
      } else if (this.usrCtrCd === 'VN') {
        filenm = 'ExcelTemplateVN.xls'
      } else if (this.usrCtrCd !== 'JP') {
        filenm = 'ExcelTemplate.xls'
      } else {
        filenm = 'ExcelTemplateJP.xls'
      }

      const result = await bookings.excelDownload(filenm)
      //blob 생성
      const blob = new Blob([result.data], { type: result.headers['content-type'] })

      if (window.navigator.msSaveOrOpenBlob) { //browser Check - IE
        window.navigator.msSaveOrOpenBlob(blob, filenm)
      } else { //browser Check - 그 외
        //<a>태그에 URL화 시킨 blob객체를 연결시킨 후 다운로드
        const link = document.createElement('a')
        const url = window.URL.createObjectURL(blob)
        link.href = url
        link.target = '_self'
        link.download = filenm
        link.click()

        //url 사용 후 메모리에서 제거
        window.URL.revokeObjectURL(url)
      }
    },
    fnFileUpload () {
      const formData = new FormData()
      const inpFile = document.querySelector('#inpFile')
      formData.append('files', inpFile.files[0])

      commons.tempFileUpload(formData).then(result => {
        const fileId = result.data.fileId
        this.tempFile.id = fileId
      })
    },
    uploadPopup (compNm) {
      this.customComponent = compNm
      this.$ekmtcCommon.layerOpen('.container_upload_pop .popup_dim')
    },
    async containerUpload () {
      const containerList = this.containerList
      let bkgNo = this.parentInfo.bkgNo
      let blNo = this.parentInfo.blNo
      let polPortCd = this.parentInfo.polPortCd
      let usrCtrCd = this.usrCtrCd
      let TH = this

      let failYn = ''
      let socCntrNo = []
      let checkMyCntrNo = true
      containerList.forEach(c => {
        if (c.cntrErrorYn === 'Y' || c.cntrSzErrorYn === 'Y' || c?.feUnitCdErrorYn === 'Y' || c?.shprVanYnErrorYn === 'Y') {
          failYn = 'Y'
        }

        if (c.socYn === 'Y') {
          socCntrNo.push(c.cntrNo)
        }
      })

      let isOk = true
      //SR 1362717 Request Container Error Message on ICC and e-kmtc Website
      if (usrCtrCd === 'MY' && 'PKG, PKW, PGU, PEN'.indexOf(polPortCd) > -1) {
        if (containerList.length > 0) {
          for (let c of containerList) {
            if (this.$ekmtcCommon.isNotEmpty(c.cntrNo)) {
              let check = await TH.checkCntrNoInMy(bkgNo, c.cntrNo, blNo)
              if (!check && isOk) {
                isOk = false
              }
            }
          }
        }
      }

      if (failYn === 'Y') {
        //this.$ekmtcCommon.alertDefault(this.$t('art.CMATK363', { socCntrNo: socCntrNo }))
        this.$ekmtcCommon.alertDefault(this.$t('msg.CSBK100.288', { socCntrNo: socCntrNo })) // 컨테이너 정보를 확인하세요.
        return
      }

      this.$emit('selectFunc', this.containerList)
      this.$emit('close')
    },
    isRed (vo, flag) {
      let returnClass = ''

      if (flag === 'cntrNo') {
        if (vo.cntrErrorYn === 'Y') {
          returnClass = 'color_red'
        }
      } else if (flag === 'cntrSz') {
        if (vo.cntrSzErrorYn === 'Y') {
          returnClass = 'color_red'
        } else if (vo.cntrSzErrorYn === 'X') {
          returnClass = 'color_blue'
        }
      } else if (flag === 'shprVanYn') {
        if (vo.shprVanYnErrorYn) {
          returnClass = 'color_red'
        }
      } else if (flag === 'feUnitCd') {
        if (vo.feUnitCdErrorYn) {
          returnClass = 'color_red'
        }
      }

      return returnClass
    },
    removeBLContainer (idx) {
      this.containerList.splice(idx, 1)
    },
    async checkCntrNoInMy (bkgNo, cntrNo, blNo) {
      if (this.parentInfo.type === 'BL') {
        bkgNo = null
      } else if (this.parentInfo.type === 'SR') {
        blNo = null
      }

      let result = true
      await trans.checkCntrNoInMy(bkgNo, cntrNo, blNo).then(res => {
        if (res.data === 'N') {
          this.$ekmtcCommon.alertDefault('The Container number not assigned to this booking. Please recheck')
          result = false
        }
      })
      return result
    }
  }
})
</script>
